<template>
  <div class="login-body">
    <Toast />
    <div class="card login-panel p-fluid">
      <div class="login-panel-content">
        <div class="grid">
          <div class="col-12 sm:col-6 md:col-6 logo-container">
            <img style="height: 70px" class="m-0" src="layout/images/duon_azul.png"
              alt="Logo da empresa, formando a palavra Duon" />
          </div>
          <div class="col-12 username-container">
            <label>E-mail</label>
            <div class="login-input">
              <InputText v-model="username" id="input" type="text" @keyup.enter="login" />
            </div>
          </div>
          <div class="col-12 password-container">
            <label>Senha</label>
            <div class="login-input">
              <InputText v-model="password" type="password" @keyup.enter="login" />
            </div>
          </div>
          <div class="col-6 rememberme-container">
            <Checkbox v-model="checked" :binary="true" />
            <label> Lembrar</label>
          </div>

          <div class="col-6 mt-1 forgetpassword-container">
            <router-link to="/recuperar" class="forget-password" exact>Esqueceu a senha?</router-link>
          </div>

          <div class="col-12 sm:col-6">
            <Button :loading="loading" label="Acessar" icon="pi pi-user" @click="login" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../services/auth";

export default {
  data() {
    return {
      username: "",
      password: "",
      checked: false,
      loading: false,
    };
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        await login(this.username, this.password);

        this.$router.replace(this.$route.query.redirect || "/");
      } catch (err) {
        const mensagem = err?.response?.data?.message ?? "Usuário ou senha inválidos!";
        this.loading = false;
        this.$toast.add({
          severity: "error",
          summary: mensagem,
          life: 3000,
        });
      }
      this.loading = false;
    },
  },
};
</script>
